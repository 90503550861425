import { PORTFOLIO_NAME_IN_API_BODY } from "../../enums/AssetOverview/portfolio";
import { ENERGY_DASHBOARD_NAME_API_BODY } from "../../enums/energyDashboard";

const urlEnergyData = process.env.REACT_APP_ENERGY_DASHBOARD_API_URL


export default {
  configs: {
    get: (projectId, type) => `${urlEnergyData}/api/config?config_filter=${type}&id=${projectId}`,
    delete: (id, type) => {
      let idKeyName = ''
      if (type === ENERGY_DASHBOARD_NAME_API_BODY)
        idKeyName = 'project_id'
      else if (type === PORTFOLIO_NAME_IN_API_BODY)
        idKeyName = 'user_id'
      else return
      return `${urlEnergyData}/api/config?config_filter=${type}&${idKeyName}=${id}`
    },
    post: () => `${urlEnergyData}/api/config`,
  },
};
