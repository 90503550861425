export default {
  uploadImage: "upload image",
  dragToUpload: "drag & drop your picture or browse to upload",
  successfulImgaeUpload: "your image has successfully been uploaded!",
  OK: "Okay",
  sorry: "sorry",
  browse: "Browse",
  upload: "upload",
  uploadFiles: "upload files",
  successfulFileUpload: "Your file has successfully been uploaded!",
  dragToUploadFile: "Drag & drop your file or browse to upload.",
  uploadingFile: "uploadingFile",
  cancel: "cancel",
  getDataProblem: "There was a problem getting data!",
  noRecords: "There are no records to show!",
  downloadFileProblem: "Sorry! There was a problem downloading the files.",
  resetAnyway: 'Reset anyway',
  apply: 'Apply',
  loadingDataPleaseWait: 'Loading data, please wait...',
  noData: 'No Data!',
  perM2: 'Per m²',
  run: 'Run',
  processingData: 'Processing Data',
  painting: 'Painting',
  resetToDefault: 'reset To Default',
  save: 'save',
  addNewChart: "add new chart",
  successfulSave: 'Saved successfully!',
  failedSave: 'Save failed. Please try again later!',
  successfulReset: 'Reset successfully!',
  failedReset: 'Reset failed. Please try again later!',
  colors: "colors"
};
