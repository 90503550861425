import { getColumnDisplayName, settingsOptions } from "../../utils/dynamicDashboard";
import { chartTypesEnums, colorCategoriesEnums } from "../dynamicDashboard";



export const getFinancialDashboardColumnDisplayName = ({ colName }) => {
    return getColumnDisplayName({ colName, dataMapping })
}

export const FiltersNames = Object.freeze({
    DIAGRAMS: 'diagrams',
    X_AXIS: 'xAxis',
    Y_AXIS: 'yAxis',
    DATAPOINT: 'dataPoint',
    TITLE: 'title',
    SORT_VALUE: 'sortValue',
    LEGEND_POSITION: 'legendsPosition',
    INVERT_X_AXIS: 'invertXAxis',
    INVERT_Y_AXIS: 'invertYAxis',
    DIAGRAMS_COLOR: 'color',
    DIAGRAMS_DIRECTION: 'direction',
    DIAGRAMS_STACKS: 'stacks',
    DIAGRAMS_PARTITIONS: 'partitions',
    DIAGRAMS_DIAGRAM_TYPE: 'diagramType',
    DIAGRAMS_DIAGRAM_DATA_KEY: 'dataKey',
    AGGREGATE_X_AXIS: 'aggregateXAxis',
    SHOW_PIE_LABELS: 'showPieLabels'
})


export const columnsDisplayNamesEnums = Object.freeze({
    ID: "ID",
    ROI: "Roi",
    // UTILTIY_COST_REDUCTION: "utility_cost_reduction",
    // CARBON_DIOXIDE_REDUCTION: "carbon_dioxide_reduction",
    // EMBODIED_CARBON: "embodied_carbon",
    INVESTMENTS: "Investment costs",
    SERVICES_COST: "Operating costs",
    NAME: "Name",
    COMPONENT: "Component",
    SUB_COMPONENT: "Sub component",
    TYPE_OF_MESSURE: "Type of measure",
    COST_SAVINGS: "Cost savings",
    CARBON_DIOXIDE_SAVINGS: "CO₂ emission reduction",
    ELECTRICITY_REDUCTION: "Reduction in electricity",
    HEAT_REDUCTION: "Reduction in heat energy",
    TOTAL_ENERGY_REDUCTION: "Reduction in energy",
})



export const columnsAPINamesEnums = Object.freeze({
    ID: "id",
    ROI: "roi",
    // UTILTIY_COST_REDUCTION: "utility_cost_reduction",
    // CARBON_DIOXIDE_REDUCTION: "carbon_dioxide_reduction",
    // EMBODIED_CARBON: "embodied_carbon",
    INVESTMENTS: "investments",
    SERVICES_COST: "services_cost",
    NAME: "name",
    COMPONENT: "component",
    SUB_COMPONENT: "sub_component",
    TYPE_OF_MESSURE: "type_of_measure",
    COST_SAVINGS: "cost_savings",
    CARBON_DIOXIDE_SAVINGS: "carbon_dioxide_savings",
    ELECTRICITY_REDUCTION: "electricity_reduction",
    HEAT_REDUCTION: "energy_reduction",
    TOTAL_ENERGY_REDUCTION: "total_energy_reduction",
})





export const dataMapping = {
    [columnsAPINamesEnums.ID]: [columnsDisplayNamesEnums.ID],
    [columnsAPINamesEnums.ROI]: [columnsDisplayNamesEnums.ROI],
    [columnsAPINamesEnums.UTILTIY_COST_REDUCTION]: [columnsDisplayNamesEnums.UTILTIY_COST_REDUCTION],
    [columnsAPINamesEnums.CARBON_DIOXIDE_REDUCTION]: [columnsDisplayNamesEnums.CARBON_DIOXIDE_REDUCTION],
    [columnsAPINamesEnums.HEAT_REDUCTION]: [columnsDisplayNamesEnums.HEAT_REDUCTION],
    [columnsAPINamesEnums.EMBODIED_CARBON]: [columnsDisplayNamesEnums.EMBODIED_CARBON],
    [columnsAPINamesEnums.INVESTMENTS]: [columnsDisplayNamesEnums.INVESTMENTS],
    [columnsAPINamesEnums.SERVICES_COST]: [columnsDisplayNamesEnums.SERVICES_COST],
    [columnsAPINamesEnums.NAME]: [columnsDisplayNamesEnums.NAME],
    [columnsAPINamesEnums.COMPONENT]: [columnsDisplayNamesEnums.COMPONENT],
    [columnsAPINamesEnums.SUB_COMPONENT]: [columnsDisplayNamesEnums.SUB_COMPONENT],
    [columnsAPINamesEnums.TYPE_OF_MESSURE]: [columnsDisplayNamesEnums.TYPE_OF_MESSURE],
    [columnsAPINamesEnums.COST_SAVINGS]: [columnsDisplayNamesEnums.COST_SAVINGS],
    [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS]: [columnsDisplayNamesEnums.CARBON_DIOXIDE_SAVINGS],
    [columnsAPINamesEnums.ELECTRICITY_REDUCTION]: [columnsDisplayNamesEnums.ELECTRICITY_REDUCTION],
    [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION]: [columnsDisplayNamesEnums.TOTAL_ENERGY_REDUCTION],
}

export const categoricalColumns = [
    columnsAPINamesEnums.NAME,
    columnsAPINamesEnums.COMPONENT,
    columnsAPINamesEnums.SUB_COMPONENT,
    columnsAPINamesEnums.TYPE_OF_MESSURE,
]


export const columnsUnits = Object.freeze({
    [columnsAPINamesEnums.ID]: '',
    [columnsAPINamesEnums.ROI]: 'a',
    [columnsAPINamesEnums.HEAT_REDUCTION]: 'kWh/m²',
    [columnsAPINamesEnums.INVESTMENTS]: '€/m²',
    [columnsAPINamesEnums.SERVICES_COST]: '€/m²',
    [columnsAPINamesEnums.NAME]: '',
    [columnsAPINamesEnums.COMPONENT]: '',
    [columnsAPINamesEnums.SUB_COMPONENT]: '',
    [columnsAPINamesEnums.TYPE_OF_MESSURE]: '',
    [columnsAPINamesEnums.COST_SAVINGS]: '€/m²',
    [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS]: 'kg/m²/a',
    [columnsAPINamesEnums.ELECTRICITY_REDUCTION]: 'kWh/m²',
    [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION]: 'kWh/m²',
})


export const defaultColors = Object.freeze({
    [colorCategoriesEnums.COLUMNS]: {
    },
    [colorCategoriesEnums.VALUES]: {

    }
})


const relevantConfigOptions = {
    [chartTypesEnums.AREA]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.HEIGHT_100,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.SORT_BY,
        settingsOptions.DATA_POINT,
        settingsOptions.TITLE,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.BAR]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.SORT_BY,
        settingsOptions.DATA_POINT,
        settingsOptions.TITLE,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.PIE]: [
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.TITLE,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.SHOW_PIE_LABELS
    ],
    [chartTypesEnums.DOUGHNUT]: [
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.TITLE,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.SHOW_PIE_LABELS
    ],
    [chartTypesEnums.LINE]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.SORT_BY,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.COMPOSED]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.QUANTITY,
        settingsOptions.SORT_BY,
        settingsOptions.DIAGRAM_TYPE,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.AGGREGATE_X_AXIS
    ],
    [chartTypesEnums.KPI]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.DATA_POINT
    ],
    [chartTypesEnums.HISTOGRAM]: [
        settingsOptions.TITLE,
        settingsOptions.QUANTITY,
        settingsOptions.SORT_BY,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.HEATMAP]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.Y,
        settingsOptions.COLOR,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.MINI_MAP]: [
        settingsOptions.DATA_POINT,
        settingsOptions.COLOR_BAR_RANGE
    ],
};

export const isRelevantOption = (chartType, option, index) => {
    if (
        index > 0 &&
        chartType !== chartTypesEnums.HISTOGRAM &&
        chartType !== chartTypesEnums.COMPOSED
    )
        return false;
    return relevantConfigOptions[chartType]?.includes(option);
};


// export const FINANCIAL_DASHBOARD_NAME_IN_API_BODY = 'portfolio'
