import { useCallback, useMemo } from "react"
import { DynamicDashboardMultiTab } from "../chart/DynamicDashboardMultiTab"
import { categoricalColumns, columnsAPINamesEnums, columnsUnits, defaultColors, FiltersNames, getFinancialDashboardColumnDisplayName, isRelevantOption } from "../../enums/Modernization/financialDashboard"
import { deleteKeysFromObjects, distinctFilter } from "../../utils/dataManipulation"
import { chartTypesEnums, diagramTypes, diagramTypesDisplayOptions, getSortOptions } from "../../enums/dynamicDashboard"
import { getColorEntity, handleConfigChange, settingsOptions } from "../../utils/dynamicDashboard"
import { FilterTypes } from "../../enums/components"

export const FinancialDashbaord = ({ data, projectId }) => {
    const dataColumns = Object.values(columnsAPINamesEnums)
    let { generalDashboardData, numericalColumns } = useMemo(() => {
        const allColumns = Object.keys(data?.[0] ?? {})
        const toBeRemovedColumns = allColumns?.filter(col => !dataColumns.includes(col))

        const shreinkedData = deleteKeysFromObjects(data ?? [], toBeRemovedColumns)
        const generalDashboardData = shreinkedData.map(row => {
            return {
                ...row,
                [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION]: row[columnsAPINamesEnums.ELECTRICITY_REDUCTION] + row[columnsAPINamesEnums.HEAT_REDUCTION]
            }
        })
        const numericalColumns = dataColumns.filter(col => !categoricalColumns.includes(col))
        return { generalDashboardData, numericalColumns }

    }, [data])

    const setFilteredData = () => { }
    const useGetSpecificData = (_config) => {
        return { specificData: [], isLoading: false, isError: false }
    }

    const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
        const { dataPoint, chartType, diagrams, xAxis, yAxis } = config

        return JSON.stringify([dataPoint, chartType, diagrams, xAxis, yAxis])
    }, [])

    const dataTransformator = useCallback((data, { dataPoint, chartType, diagrams, xAxis, yAxis }) => {
        let output = []
        const { partitions, stacks } = diagrams?.[0]
        if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {

            const flattenData = data?.map((row) => {
                const filteredRow = {};
                if (partitions) filteredRow[partitions] = row[partitions];
                if (stacks) filteredRow[stacks] = row[stacks];
                return {
                    ...filteredRow,
                    dataPoint,
                    [dataPoint]: row[dataPoint],
                };
            });
            output = { flattenData: flattenData || [] }
        }

        else if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE, chartTypesEnums.KPI].includes(chartType)) {

            const flattenData = data?.map((row) => {
                const filteredRow = {};
                if (partitions) filteredRow[partitions] = row[partitions];
                if (stacks) filteredRow[stacks] = row[stacks];
                if (xAxis) filteredRow[xAxis] = row[xAxis];
                return {
                    ...filteredRow,
                    dataPoint,
                    [dataPoint]: row[dataPoint],
                };
            });
            output = { flattenData: flattenData || [] }
        }

        else if (chartType === chartTypesEnums.HEATMAP) {

            const flattenData = data?.map((row) => {
                const filteredRow = {};
                if (yAxis) filteredRow[yAxis] = row[yAxis];
                if (xAxis) filteredRow[xAxis] = row[xAxis];
                return {
                    ...filteredRow,
                    dataPoint,
                    [dataPoint]: row[dataPoint],
                };
            });
            const xLabels = data
                ?.map((row) => row[xAxis])
                ?.filter(distinctFilter);
            xLabels?.sort()

            const yLabels = data
                ?.map((row) => row[yAxis])
                ?.filter(distinctFilter);
            yLabels?.sort()

            output = { flattenData: flattenData || [], xLabels, yLabels }
        } else {
            output = { flattenData: data || [] }
        }

        return output

    }, [])


    const dynamicChartsRowHeight = 50;

    const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], [])
    const getColumnDisplayNameCallback = useCallback((props) => getFinancialDashboardColumnDisplayName(props), [])

    const defaultPortfolioConfigsAndColorsConfigs = {
        "colors": {
            "Columns": {

            },
            "Values": {

            },
            "Mixed": {}
        },
        "tabs": [
            {
                "tab": "Overview",
                "filters": {},
                "settings": [

                ],
                "selectedFiltersNames": []
            },

        ]
    }

    const dataColumnsDisplayOptions = dataColumns?.map(colName => getFinancialDashboardColumnDisplayName({ colName }))

    const allSettings = (updateChart, config) => [
        {
            filterName: FiltersNames.TITLE,
            filterLabel: 'Chart title',
            filterType: FilterTypes.INPUT,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                className: "block w-3/4 ",
                label: "Title",
                placeholder: "Title",
                height: "4rem"
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
            isInDiagramsPart: false,
        },
        {
            mainDataKey: 'dataPoint',
            filterName: FiltersNames.DATAPOINT,
            filterLabel: 'Metric',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Metric",
                options: dataColumns,
                displayOptions: dataColumnsDisplayOptions,
                width: "75%",
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.X_AXIS,
            filterLabel: 'X Axis',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "x-axis",
                options: [...dataColumns],
                displayOptions: [...dataColumnsDisplayOptions],
                className: "w-3/4"
            },
            visible: () => ([chartTypesEnums.COMPOSED].includes(config.chartType)) &&
                isRelevantOption(config.chartType, settingsOptions.X,),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.X_AXIS,
            filterLabel: 'X Axis',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "x-axis",
                options: [...dataColumns],
                displayOptions: [...dataColumnsDisplayOptions],
                className: "w-3/4"
            },
            visible: () => ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE, chartTypesEnums.HEATMAP].includes(config.chartType)) &&
                isRelevantOption(config.chartType, settingsOptions.X,),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.AGGREGATE_X_AXIS,
            filterLabel: 'Aggregation',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Aggregation",
                options: [0, 1],
                displayOptions: ["Don't aggregate", "Aggregate"],
                className: "w-3/4"
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.AGGREGATE_X_AXIS,),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.INVERT_X_AXIS,
            filterLabel: 'Invert X Axis',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Invert X Axis",
                options: [0, 1],
                displayOptions: ["No", "Yes"],
                className: "block w-3/4 ",
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.Y_AXIS,
            filterLabel: 'Y Axis',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "y-axis",
                options: [...dataColumns],
                displayOptions: [...dataColumnsDisplayOptions],
                className: "w-3/4"
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.Y,),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.INVERT_Y_AXIS,
            filterLabel: 'Invert Y Axis',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Invert Y Axis",
                options: [0, 1],
                displayOptions: ["No", "Yes"],
                className: "block w-3/4 ",
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.LEGEND_POSITION,
            filterLabel: 'Lengeds Position',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Lengeds position",
                options: ['bottom', 'right'],
                displayOptions: ['Bottom', 'Right'],
                width: "75%"
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.SHOW_PIE_LABELS,
            filterLabel: 'Show labels',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Show labels",
                options: [0, 1],
                displayOptions: ["No", "Yes"],
                width: "75%"
            },
            visible: () => isRelevantOption(config.chartType, settingsOptions.SHOW_PIE_LABELS),
            isInDiagramsPart: false,
        },
        {
            filterName: FiltersNames.SORT_VALUE,
            filterLabel: 'Sort by',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            setConfig: (updateChart) => updateChart,
            props: {
                label: "Sort by",
                options: getSortOptions({ chartType: config.chartType })?.options,
                displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
                className: "block w-3/4 "
            },
            visible: () => {
                return isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
                    !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
            },
            isInDiagramsPart: false,
        },
        // {
        //   mainDataKey: undefined,
        //   useAsDataFilter: false,
        //   filterName: FiltersNames.PER_M2,
        //   filterLabel: 'Per m²',
        //   filterType: FilterTypes.DROPDOWN_SINGLE,
        //   adjustConfig: (config) => config,
        //   setConfig: (updateChart) => updateChart,
        //   props: {
        //     label: "Per m²",
        //     options: [0, 1],
        //     displayOptions: ["No", "Yes"],
        //     width: "75%",
        //   },
        //   visible: () => isRelevantOption(config.chartType, settingsOptions.PER_M2),
        //   isInDiagramsPart: false,
        // },
        {
            filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
            filterLabel: 'Y Axis',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
            props: {
                label: "Y Axis",
                options: [...dataColumns],
                displayOptions: [...dataColumnsDisplayOptions],
                className: "block w-3/4 ",
            },
            visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
            isInDiagramsPart: true,
        },
        // {
        //   mainDataKey: columnsAPINamesEnums.SCENARIO,
        //   useAsDataFilter: false,
        //   filterName: FiltersNames.DIAGRAMS_SCENARIO,
        //   filterLabel: 'Scenario',
        //   filterType: FilterTypes.DROPDOWN_SINGLE,
        //   adjustConfig: (config) => config,
        //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
        //   props: {
        //     label: "Scenario",
        //     options: scenarios,
        //     displayOptions: scenarios,
        //     width: "75%",
        //   },
        //   visible: () => isRelevantOption(config.chartType, FiltersNames.DIAGRAMS_SCENARIO,),
        //   isInDiagramsPart: true,
        // },
        {
            filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
            filterLabel: 'Diagram type',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
            props: {
                label: "Diagram type",
                options: Object.values(diagramTypes).filter(type => type !== diagramTypes.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0),
                displayOptions: Object.values(diagramTypesDisplayOptions).filter(type => type !== diagramTypesDisplayOptions.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0),
                className: "block w-3/4 ",
            },
            visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
            isInDiagramsPart: true,
        },
        {
            filterName: FiltersNames.DIAGRAMS_PARTITIONS,
            filterLabel: 'Partitions',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
            props: {
                label: "Partitions",
                options: [...dataColumns],
                displayOptions: [...dataColumnsDisplayOptions],
                className: "block w-3/4 ",
            },
            visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
            isInDiagramsPart: true,
        },
        {
            filterName: FiltersNames.DIAGRAMS_STACKS,
            filterLabel: 'Stacks',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
            props: {
                label: "Stacks",
                options: [...dataColumns],
                displayOptions: [...dataColumnsDisplayOptions],
                className: "block w-3/4 ",
            },
            visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
            isInDiagramsPart: true,
        },
        {
            filterName: FiltersNames.DIAGRAMS_DIRECTION,
            filterLabel: 'Direction',
            filterType: FilterTypes.DROPDOWN_SINGLE,
            adjustConfig: (config) => config,
            adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
            props: {
                label: "Direction",
                options: ["horizontal", "vertical"],
                displayOptions: ["Horizontal", "Vertical"],
                className: "block w-3/4 ",
            },
            visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
            isInDiagramsPart: true,
        },
    ]

    const nameForLocalStorage = `financialDashboardConfigs-${projectId}`

    const loadDashboardConfigs = () => {
        let dashboardConfigs = localStorage.getItem(nameForLocalStorage);
        if (dashboardConfigs) {
            dashboardConfigs = JSON.parse(dashboardConfigs);
        } else {
            dashboardConfigs = defaultPortfolioConfigsAndColorsConfigs;
        }
        return dashboardConfigs
    };

    let savedPortfolioAndColorsConfigsMemoized = useMemo(() => {
        let savedPortfolioAndColorsConfigs = loadDashboardConfigs(projectId)
        return savedPortfolioAndColorsConfigs
    }, [])

    const onResetDashboardConfigs = () => {
        localStorage.removeItem(nameForLocalStorage);
    };

    const saveDashboardConfigs = (data) => {
        localStorage.setItem(nameForLocalStorage, JSON.stringify(data));
    }


    const chartTypes = useMemo(() => [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.COMPOSED, chartTypesEnums.DOUGHNUT,
    chartTypesEnums.HEATMAP, chartTypesEnums.HISTOGRAM, chartTypesEnums.KPI, chartTypesEnums.LINE, chartTypesEnums.PIE
    ], [])

    return (
        <DynamicDashboardMultiTab
            filteredData={generalDashboardData}
            setFilteredData={setFilteredData}
            generalDashboardData={generalDashboardData}
            savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigsMemoized}
            onSave={saveDashboardConfigs}
            onReset={onResetDashboardConfigs}
            defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}

            filtersCategories={[]}
            categoricalColumns={categoricalColumns}
            numericalColumns={numericalColumns}
            defaultColors={defaultColors}
            getColorEntity={getColorEntity}
            allAvailableFilters={() => []}

            dynamicChartsRowHeight={dynamicChartsRowHeight}
            allSettings={allSettings}
            allFilters={() => []}
            useGetSpecificData={useGetSpecificData}
            getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
            dataTransformator={dataTransformator}
            getUnit={getUnit}
            chartTypes={chartTypes}

            setTriggerFlag={() => { }}
            renderMiniMapObjectClickModal={() => <></>}
            IdColumnName={columnsAPINamesEnums.ID}
            getColumnDisplayName={getColumnDisplayNameCallback}
            multithread={false}
        />
    )
}