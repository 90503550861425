import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  useGetBuilding,
  useGetDashboardNew,
  useGetEnergyDashbordConfigs,
  useGetProjectInfo
} from "../../api/hooks/allHooks";
import { distinctFilter, keepKeysFromObjects, minMaxByKey } from "../../utils/dataManipulation";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
// import { GeneralErrorBoundary } from "../ErrorBoundary";
import { FilterTypes } from "../../enums/components";
import { chartTypesEnums, colorCategoriesEnums, defaultColors, diagramTypes, diagramTypesDisplayOptions, getSortOptions, valuesEnums } from "../../enums/dynamicDashboard";
import { categoricalColumns, categoricalColumnsDisplayNames, columnsAPINamesEnums, columnsDisplayNamesEnums, columnsUnits, dataMapping, ENERGY_DASHBOARD_NAME_API_BODY, FILTER_DATA_TO_NONE_ZERO_UTILITIES, FiltersNames, getEnergyDashboardColumnDisplayName, isRelevantOption, staticUtilities, staticUtilitiesDisplayNameTemporary, staticUtilitiesTemporary, } from "../../enums/energyDashboard";
import { convertYearMonthToValidDate, getMonthNumber, getWeekdayNumber, weekDayMapping } from "../../utils/date";
import { aggregatedSumValue, getColorEntity, getColumnDisplayName, handleConfigChange, settingsOptions } from "../../utils/dynamicDashboard";
import { getFlattenData } from "../../utils/energyDashboard";
import { DynamicDashboardMultiTab } from "../chart/DynamicDashboardMultiTab";
import { sum } from "lodash";

import * as Comlink from 'comlink';
import { deleteEnergyDashboardConfigs, postEnergyDashboardConfigs } from "../../api/functions/all";
import { QueryClient, useQueryClient } from "react-query";

// const defaultDashboardConfigs = [
//   {
//     "type": "1",
//     "id": 0,
//     "x": 0,
//     "y": 8,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "line",
//     "title": "Energy Demand",
//     "xAxis": "hourOfYear",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "hourly",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-06-29T23:00:00.000Z",
//     "selectedUtilities": [
//       "coolingDemand_avg",
//       "electricityDemand_avg",
//       "heatingDemand_avg",
//       "hotWaterDemand_avg"
//     ],
//     "selectedScenarios": [
//       "Timestamps Occupancy-60"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 1,
//     "x": 0,
//     "y": 14,
//     "w": 6,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": "loadsLuminanceInternal_avg",
//         "sensorDisplayText": null,
//         "diagramType": "scatter",
//         "color": "#ea4d4d",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       },
//       {
//         "dataPoint": null,
//         "dataKey": "loadsSolarRadiation_avg",
//         "sensorDisplayText": null,
//         "diagramType": "scatter",
//         "color": "#65abec",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       },
//       {
//         "dataPoint": null,
//         "dataKey": "loadsVentilation_avg",
//         "sensorDisplayText": null,
//         "diagramType": "scatter",
//         "color": "#3fff0a",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "composed",
//     "title": "Cloud Diagram",
//     "xAxis": "loadsConduction_avg",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-02-26T00:00:00.000Z",
//     "selectedUtilities": null,
//     "selectedScenarios": [
//       "Timestamps Occupancy-60"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 2,
//     "x": 6,
//     "y": 14,
//     "w": 6,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": "ventilationInfiltrationTemperature_avg",
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "#f20d0d",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       },
//       {
//         "dataPoint": null,
//         "dataKey": "ventilationNaturalTemperature_avg",
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "#2ce407",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "histogram",
//     "title": "Ventilation Histogram",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-03-30T23:00:00.000Z",
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 4,
//     "x": 0,
//     "y": 20,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": "All values",
//     "chartType": "line",
//     "title": "Energy  load",
//     "xAxis": "dayOfYear",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-12-31T00:00:00.000Z",
//     "selectedUtilities": [
//       "heatingDemand_avg",
//       "coolingDemand_avg",
//       "electricityDemand_avg",
//       "hotWaterDemand_avg",
//       "loadsConduction_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 5,
//     "x": 4,
//     "y": 0,
//     "w": 8,
//     "h": 8,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "vertical",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "bar",
//     "title": "Annual Energy Demand",
//     "xAxis": "",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-07-30T23:00:00.000Z",
//     "selectedUtilities": [
//       "loadsLuminanceInternal_avg",
//       "loadsSolarRadiation_avg",
//       "loadsTechnicalAppliancesInternal_avg",
//       "loadsWallStorage_avg"
//     ],
//     "selectedScenarios": [
//       "Timestamps Occupancy-60",
//       "Timestamps Occupancy-0"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "right"
//   },
//   {
//     "type": "1",
//     "id": 6,
//     "x": 0,
//     "y": 26,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "bar",
//     "title": "Monthly Energy Demand",
//     "xAxis": "yearMonth",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": [
//       "ventilationInfiltrationTemperature_avg",
//       "ventilationMechanicalFlowRate_avg",
//       "ventilationMechanicalTemperature_avg"
//     ],
//     "selectedScenarios": [
//       "Timestamps Occupancy-60",
//       "Timestamps Occupancy-0"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "right"
//   },
//   {
//     "type": "1",
//     "id": 7,
//     "x": 6,
//     "y": 32,
//     "w": 6,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "doughnut",
//     "title": "Ventilation Distribution",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": [
//       "ventilationMechanicalTemperature_avg",
//       "ventilationNaturalTemperature_avg",
//       "ventilationMechanicalFlowRate_avg",
//       "ventilationInfiltrationTemperature_avg",
//       "ventilationInfiltrationFlowRate_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "right"
//   },
//   {
//     "type": "1",
//     "id": 8,
//     "x": 0,
//     "y": 39,
//     "w": 12,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "#ea0606",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "heatmap",
//     "title": "Carpet Plot",
//     "xAxis": "dayOfYear",
//     "yAxis": "hourOfDay",
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "hourly",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-06-29T23:00:00.000Z",
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 9,
//     "x": 0,
//     "y": 46,
//     "w": 12,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "bar",
//     "title": "Thermal Load Profile",
//     "xAxis": "hourOfYear",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "hourly",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-06-29T23:00:00.000Z",
//     "selectedUtilities": [
//       "loadsPeopleInternal_avg",
//       "loadsSolarRadiation_avg",
//       "loadsTechnicalAppliancesInternal_avg",
//       "loadsWallStorage_avg",
//       "loadsVentilation_avg",
//       "loadsLuminanceInternal_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 10,
//     "x": 0,
//     "y": 53,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "scenario",
//         "stacks": "dayOfWeek",
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "pie",
//     "title": "Days Consumption Comparison",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "bottom"
//   },
//   {
//     "type": "1",
//     "id": 11,
//     "x": 0,
//     "y": 0,
//     "w": 4,
//     "h": 8,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "vertical",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "kpi",
//     "title": "Total Consumption",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": [
//       "coolingDemand_avg",
//       "electricityDemand_avg",
//       "heatingDemand_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "bottom"
//   },
//   {
//     "type": "1",
//     "id": 12,
//     "x": 0,
//     "y": 32,
//     "w": 6,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "scenario",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "area",
//     "title": "Scenarios Monthly Consumption",
//     "xAxis": "yearMonth",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "bottom"
//   }
// ]

const defaultPortfolioConfigsAndColorsConfigs = {
  "colors": {
    "Columns": {
      "electricityDemand_avg": "#ed8002",
      "heatingDemand_avg": "#bc19c8",
      "hotWaterDemand_avg": "#0d1dfd",
      "coolingDemand_avg": "#77e4c0"
    },
    "Values": {
      "datetime": {},
      "dayOfWeek": {},
      "month": {},
      "yearMonth": {},
      "scenario": {},
      "isWorkingDay": {},
      "isWorkingHour": {}
    },
    "Mixed": {}
  },
  "tabs": [
    {
      "tab": "Overview",
      "filters": {},
      "settings": [
        {
          "type": "1",
          "id": 0,
          "x": 6,
          "y": 0,
          "w": 6,
          "h": 6,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "utility",
              "stacks": "scenario",
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "kpi",
          "title": "Consumption",
          "xAxis": null,
          "yAxis": null,
          "dataPoint": "consumption",
          "legendsPosition": "bottom",
          "selectedUtilities": [],
          "selectedScenarios": []
        },
        {
          "type": "1",
          "id": 1,
          "x": 0,
          "y": 0,
          "w": 6,
          "h": 6,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "utility",
              "stacks": "scenario",
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "pie",
          "title": null,
          "xAxis": null,
          "yAxis": null,
          "dataPoint": "consumption",
          "legendsPosition": "bottom",
          "selectedUtilities": []
        },
        {
          "type": "1",
          "id": 2,
          "x": 0,
          "y": 6,
          "w": 12,
          "h": 7,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "utility",
              "stacks": "scenario",
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "bar",
          "title": null,
          "xAxis": "yearMonth",
          "yAxis": null,
          "dataPoint": "consumption",
          "legendsPosition": "right",
          "selectedUtilities": []
        }
      ],
      "selectedFiltersNames": []
    },
    {
      "filters": {},
      "settings": [
        {
          "type": "1",
          "id": 1,
          "x": 6,
          "y": 0,
          "w": 6,
          "h": 6,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "utility",
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": "y",
          "chartType": "area",
          "title": "All scenarios across week days",
          "xAxis": "dayOfWeek",
          "yAxis": null,
          "dataPoint": "consumption",
          "legendsPosition": "bottom",
          "selectedUtilities": []
        },
        {
          "type": "1",
          "id": 2,
          "x": 0,
          "y": 0,
          "w": 6,
          "h": 6,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "utility",
              "stacks": null,
              "percentageBased": false,
              "direction": "vertical",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "bar",
          "title": "Scenarios comparison",
          "xAxis": "scenario",
          "yAxis": null,
          "dataPoint": "consumption",
          "legendsPosition": "bottom",
          "selectedUtilities": []
        }
      ],
      "selectedFiltersNames": [],
      "tab": "Tab 2"
    },
    {
      "filters": {},
      "settings": [
        {
          "type": "1",
          "id": 0,
          "x": 0,
          "y": 0,
          "w": 12,
          "h": 6,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "utility",
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": "All values",
          "chartType": "line",
          "title": "Simulated Load",
          "xAxis": "hourOfYear",
          "yAxis": null,
          "dataPoint": "consumption",
          "legendsPosition": "bottom",
          "timeframe": "hourly",
          "selectedUtilities": [],
          "invertXAxis": 1,
          "selectedScenarios": [],
          "selectedDaysOfWeek": [],
          "selectedHoursOfDay": []
        },
        {
          "type": "1",
          "id": 1,
          "x": 0,
          "y": 6,
          "w": 12,
          "h": 6,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": null,
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "heatmap",
          "title": "Hours Load",
          "xAxis": "hourOfDay",
          "yAxis": "dayOfWeek",
          "dataPoint": "consumption",
          "legendsPosition": "bottom",
          "timeframe": "hourly"
        }
      ],
      "selectedFiltersNames": [],
      "tab": "Hourly"
    }
  ]
}
const adjustConfigs = (configs) => {
  if (configs) {
    const adjustedConfigs = {
      ...configs, tabs: configs?.tabs?.map((tab) => ({
        ...tab,
        settings: tab.settings?.map(setting => {
          return {
            ...setting,
            startDate: setting?.startDate && new Date(setting?.startDate),
            endDate: setting?.endDate && new Date(setting?.endDate),
          }
        })
      }))
    };
    return adjustedConfigs;
  }
};

const loadDashboardDefaultConfigs = () => {
  return adjustConfigs(defaultPortfolioConfigsAndColorsConfigs);
};

const loadDashboardConfigs = (projectId) => {
  let dashboardConfigs = localStorage.getItem(`dashboardConfigs-${projectId}`);
  if (dashboardConfigs) {
    dashboardConfigs = JSON.parse(dashboardConfigs);
  } else {
    dashboardConfigs = defaultPortfolioConfigsAndColorsConfigs;
  }
  return adjustConfigs(dashboardConfigs);
};

export const removeZeroFields = (data) => {
  if (!data?.length) return;
  let keys = Object.keys(data[0]);
  for (let key of keys) {
    let allZero = data.every((item) => item[key] === 0);
    if (allZero) {
      for (let obj of data) {
        delete obj[key];
      }
    }
  }
  return data;
};

const columnsOptionsGroups = [
  {
    group: 'Temporal Features',
    options: [
      columnsAPINamesEnums.DATE_TIME,
      columnsAPINamesEnums.DAY_OF_MONTH,
      columnsAPINamesEnums.DAY_OF_WEEK,
      columnsAPINamesEnums.DAY_OF_YEAR,
      columnsAPINamesEnums.HOUR_OF_DAY,
      columnsAPINamesEnums.HOUR_OF_YEAR,
      columnsAPINamesEnums.MONTH,
      columnsAPINamesEnums.MONTH_NUMBER,
      columnsAPINamesEnums.YEAR,
      columnsAPINamesEnums.YEAR_MONTH,

      columnsAPINamesEnums.CET_HOUR,
    ]
  },
  {
    group: 'Occupancy',
    options: [
      columnsAPINamesEnums.OCCUPANCY_PERCENTAGE_AVG,
      columnsAPINamesEnums.OCCUPANCY_PEOPLE_AVG,

      columnsAPINamesEnums.IS_WORKING_DAY,
      columnsAPINamesEnums.IS_WORKING_HOUR,
    ]
  },
  {
    group: 'Temperature',
    options: [
      columnsAPINamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG,
      columnsAPINamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG,
      columnsAPINamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG,
      columnsAPINamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG,
      columnsAPINamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG,
      columnsAPINamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG,

      columnsAPINamesEnums.TEMPERATURE_AIR_SUPPLY_AVG
    ]
  },
  {
    group: 'Volume Flow',
    options: [
      columnsAPINamesEnums.VENTILATION_INFILTRATION_FLOW_RATE,
      columnsAPINamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG,
      columnsAPINamesEnums.VENTILATION_MECHANICAL_FLOW_RATE,
      columnsAPINamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG,
      columnsAPINamesEnums.VENTILATION_NATURAL_FLOW_RATE,
      columnsAPINamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG,

      columnsAPINamesEnums.FLOW_RATE_ADDITIONAL_VENTILATION_AVG,
      columnsAPINamesEnums.FLOW_RATE_INFILTRATION_AVG,
      columnsAPINamesEnums.FLOW_RATE_MECHANICAL_VENTILATION_AVG,
      columnsAPINamesEnums.FLOW_RATE_NATURAL_VENTILATION_AVG,
    ]
  },
  {
    group: 'Thermal Load',
    options: [
      columnsAPINamesEnums.LOAD_CONDUCTION_AVG,
      columnsAPINamesEnums.LOAD_LUMINANCE_INTERNAL_AVG,
      columnsAPINamesEnums.LOAD_PEOPLE_INTERNAL_AVG,
      columnsAPINamesEnums.LOAD_SOLAR_RADIATION_AVG,
      columnsAPINamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG,
      columnsAPINamesEnums.LOAD_VENTILATION_AVG,
      columnsAPINamesEnums.LOAD_WALLSTORAGE_AVG,

      columnsAPINamesEnums.LOADS_ADDITIONAL_VENTILATION_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_FLOOR_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_GROUND_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_WALLS_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_WINDOWS_AVG,
      columnsAPINamesEnums.LOADS_INFILTRATION_AVG,
      columnsAPINamesEnums.LOADS_MECHANICAL_VENTILATION_AVG,
      columnsAPINamesEnums.LOADS_NATURAL_VENTILATION_AVG,
      columnsAPINamesEnums.LOADS_OTHER_AVG,
    ]
  },
  {
    group: 'Heat Energy Demand',
    options: [
      columnsAPINamesEnums.HEATING_DEMAND,

      columnsAPINamesEnums.HEATING_DEMAND_AUXILIARY_SYSTEM_AVG,
      columnsAPINamesEnums.HEATING_DEMAND_MAIN_SYSTEM_AVG,
      columnsAPINamesEnums.HEATING_DEMAND_VENTILATION_SYSTEM_AVG,
    ]
  },
  {
    group: 'Cooling Energy Demand',
    options: [
      columnsAPINamesEnums.COOLING_DEMAND,
      columnsAPINamesEnums.COOLING_DEMAND_AUXILIARY_SYSTEM_AVG,
      columnsAPINamesEnums.COOLING_DEMAND_MAIN_SYSTEM_AVG,
      columnsAPINamesEnums.COOLING_DEMAND_VENTILATION_SYSTEM_AVG,
      columnsAPINamesEnums.COOLING_ELECTRICITY_DEMAND,
    ]
  },
  {
    group: 'Electricity Demand',
    options: [
      columnsAPINamesEnums.ELECTRICITY_DEMAND,
      columnsAPINamesEnums.ILLUMINATION_ELECTRICITY_DEMAND_AVG,
      columnsAPINamesEnums.OTHER_ELECTRICITY_DEMAND_AVG,
      columnsAPINamesEnums.TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG,
      columnsAPINamesEnums.VENTILATION_ELECTRICITY_DEMAND_AVG,
    ]
  },
  {
    group: 'Hot Water Demand',
    options: [
      columnsAPINamesEnums.HOT_WATER_DEMAND,
    ]
  },
  {
    group: 'Renewable Energy',
    options: [
      columnsAPINamesEnums.PV_ELECTRICITY_AVG,
      columnsAPINamesEnums.PV_OVERHEAD_AVG,
      columnsAPINamesEnums.PV_OWN_DEMAND,
    ]
  },

  {
    group: 'Primary Energy Demand',
    options: [
      columnsAPINamesEnums.OIL_HEATING_AVG,
      columnsAPINamesEnums.NATURAL_GAS_AVG,
      columnsAPINamesEnums.GRID_ELECTRICITY_AVG,
      columnsAPINamesEnums.DISTRICT_HEATING_AVG,
    ]
  },
  {
    group: 'Other',
    options: [
      valuesEnums.INDEX,
      valuesEnums.COUNT,
      columnsAPINamesEnums.UTILITY,
      columnsAPINamesEnums.SCENARIO,
    ]
  },

]


export const EnergyDashboardNewMultiTab = ({ }) => {
  const multithread = true
  const { projectId } = useParams();

  const { data: building } = useGetBuilding(projectId)
  const areaForM2Calculations = building?.gross_asset_basement_included

  const {
    data: projectInfo,
    isLoading: pIIsLoading,
    isSuccess,
  } = useGetProjectInfo(projectId, {});

  const projectInfoParsed = useMemo(() => {
    let scenarios = [],
      dataPoints = [],
      utilities = [],
      roomList = [];
    if (isSuccess) {
      try {
        scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
        dataPoints = JSON.parse(projectInfo[0].metrics);
        utilities = [{ metric: 'consumption', values: staticUtilities, 'display name': staticUtilities.map(utility => dataMapping[utility]) }]
        roomList = JSON.parse(projectInfo[0].room_list);
      } catch { }
    }
    return { scenarios, dataPoints, utilities, roomList };
  }, [projectInfo]);

  let scenarios = [],
    dataPoints = [];

  if (isSuccess) {
    try {
      scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
      dataPoints = JSON.parse(projectInfo[0].metrics);
    } catch { }
  }

  const inputs = {
    quantities: dataPoints
      ?.filter((e) => e.level === "building")
      ?.map((e) => e.dataKey),
    scenarios,
    projectId,
    startDate: new Date("2000-01-01"),
    endDate: new Date("2100-01-01"),
    interval: "daily",
    enabled: isSuccess,
    dataLevel: "building",
  };

  let { data, isLoading, allColumns: dataColumns } = useGetDashboardNew(inputs);
  const generalDashboardDataRaw = useMemo(() => data || [], [
    projectInfo,
    isLoading,
  ]);

  let { generalDashboardData, numericalColumns } = useMemo(() => {
    let mappedData = []
    generalDashboardDataRaw?.forEach((row) => {
      const mappedDataRow = { ...row, [columnsAPINamesEnums.COUNT]: 1 }
      mappedData.push(mappedDataRow)
    })
    dataColumns.push(columnsAPINamesEnums.COUNT)

    const numericalColumns = dataColumns.filter(col => !categoricalColumns.includes(col))

    return { generalDashboardData: mappedData, numericalColumns }

    // Don't put generalDashboardDataRaw in the dependency array as it is not memoized in useGetDashboardNew (will casue infinite rerender)
  }, [projectInfo, isLoading,])


  const { min: minDate, max: maxDate } = minMaxByKey({
    data: generalDashboardData,
    key: "datetime",
  });

  const dynamicChartsRowHeight = 50;

  const dataLevel = 'building'
  let { utilities } = projectInfoParsed;

  // if (FILTER_DATA_TO_NONE_ZERO_UTILITIES) {
  //   utilities = utilities.filter(u => Object.keys(generalDashboardData?.[0] ?? {})?.includes(u))
  // }


  const relatedSensors = utilities?.find((info) => info.metric === 'consumption');
  const allSensorsDataKeys = relatedSensors?.values ?? [];
  const allSensorsDisplayNames = relatedSensors
    ? relatedSensors["display name"]
    : [];
  const relatedDataPoints = dataPoints?.filter(
    (d) =>
      d.level === dataLevel || (d.level === "room" && dataLevel !== "building")
  );

  const getDataUtilities = (data) => {
    return allSensorsDataKeys.filter(u => Object.keys(data?.[0] ?? {})?.includes(u))
  }

  const dataPointsOptions = relatedDataPoints?.map((d) => d.dataKey);
  const dataPointsDisplayOptions = relatedDataPoints?.map((d) => d.dataKey);


  const dataColumnsDisplayOptions = dataColumns?.map(colName => getEnergyDashboardColumnDisplayName({ colName }))
  const numericalColumnsDisplayOptions = numericalColumns?.map(colName => getEnergyDashboardColumnDisplayName({ colName }))


  const dataUtilities = getDataUtilities(generalDashboardData)
  const dataUtilitiesDisplayNames = dataUtilities?.map(u => getEnergyDashboardColumnDisplayName({ colName: u }))


  const allFilters = (updateChart, config) => [
    {
      filterName: FiltersNames.SELECTED_SENSORS,
      mainDataKey: undefined,
      filterType: FilterTypes.DROPDOWN_MULTI,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        width: "75%",
        label: "Utilities",
        options: dataUtilities,
        displayOptions: dataUtilitiesDisplayNames,
        options: dataUtilities,
        displayOptions: dataUtilitiesDisplayNames,
        optionsGroups: columnsOptionsGroups,
      },
      visible: () => !staticUtilities.includes(config[FiltersNames.SUB_DATAPOINT]),
    },
    {
      mainDataKey: columnsAPINamesEnums.SCENARIO,
      filterName: FiltersNames.SELECTED_SCENARIOS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        width: "75%",
        label: "Scenarios",
        options: scenarios,
        displayOptions: scenarios,
      },
      visible: () => true,
    },
    {
      filterName: FiltersNames.DATE_RANGE,
      filterType: FilterTypes.DATERANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.DATE_TIME,
      props: {
        className: "w-3/4",
        appearance: "default",
        cleanable: false,
        minDate,
        maxDate,
        placement: config.x < 6 ? "bottomEnd" : "bottomStart"
      },
      visible: () => true,
      adjustConfig: (config) => ({ ...config, [FiltersNames.DATE_RANGE]: [config.startDate, config.endDate] }),
      setConfig: (_filterName, dates) => {
        updateChart(FiltersNames.START_DATE, dates[0])
        updateChart(FiltersNames.END_DATE, dates[1])
      }
      ,
    },
    {
      mainDataKey: undefined,
      filterName: FiltersNames.TIMEFRAME,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        className: "w-3/4",
        label: "Timeframe",
        options: ["daily", "hourly"],
        displayOptions: ["Daily", "Hourly"],
      },
      visible: () => true,
    },
    {
      mainDataKey: columnsAPINamesEnums.DAY_OF_WEEK,
      filterName: FiltersNames.SELECTED_DAYS_OF_WEEK,
      filterType: FilterTypes.DROPDOWN_MULTI,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        width: "75%",
        label: "Day",
        options: Object.values(weekDayMapping),
      },
      visible: () => true,
    },
    {
      mainDataKey: columnsAPINamesEnums.HOUR_OF_DAY,
      filterName: FiltersNames.SELECTED_HOURS_OF_DAY,
      filterType: FilterTypes.DROPDOWN_MULTI,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        width: "75%",
        label: "Hour",
        options: [...Array(24).keys()],
      },
      visible: () => true,
    },
    {
      mainDataKey: columnsAPINamesEnums.IS_WORKING_DAY,
      filterName: FiltersNames.IS_WORKING_DAY,
      filterLabel: 'Working Day',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        label: "Working Day",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => true,
    },
    {
      mainDataKey: columnsAPINamesEnums.IS_WORKING_HOUR,
      filterName: FiltersNames.IS_WORKING_HOUR,
      filterLabel: 'Working Hour',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: updateChart,
      props: {
        label: "Working Hour",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => true,
    },
  ]


  const allSettings = (updateChart, config) => [
    {
      filterName: FiltersNames.TITLE,
      filterLabel: 'Chart title',
      filterType: FilterTypes.INPUT,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        className: "block w-3/4 ",
        label: "Title",
        placeholder: "Title",
        height: "4rem"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
      isInDiagramsPart: false,
    },
    {
      mainDataKey: 'dataPoint',
      useAsDataFilter: true,
      filterName: FiltersNames.DATAPOINT,
      filterLabel: 'Metric',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Metric",
        options: dataPointsOptions,
        displayOptions: dataPointsDisplayOptions,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
      isInDiagramsPart: false,
    },
    {
      mainDataKey: undefined,
      useAsDataFilter: false,
      filterName: FiltersNames.SUB_DATAPOINT,
      filterLabel: 'Data point',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Data point",
        options: numericalColumns,
        displayOptions: numericalColumnsDisplayOptions,
        optionsGroups: columnsOptionsGroups,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.SUB_DATAPOINT),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: [columnsAPINamesEnums.UTILITY, ...dataColumns],
        displayOptions: [columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        optionsGroups: columnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.COMPOSED].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: [valuesEnums.INDEX, columnsAPINamesEnums.UTILITY, ...dataColumns],
        displayOptions: [valuesEnums.INDEX, columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        optionsGroups: columnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE, chartTypesEnums.HEATMAP].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.AGGREGATE_X_AXIS,
      filterLabel: 'Aggregation',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Aggregation",
        options: [0, 1],
        displayOptions: ["Don't aggregate", "Aggregate"],
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.AGGREGATE_X_AXIS,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_X_AXIS,
      filterLabel: 'Invert X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert X Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.Y_AXIS,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "y-axis",
        options: [valuesEnums.INDEX, columnsAPINamesEnums.UTILITY, ...dataColumns],
        displayOptions: [valuesEnums.INDEX, columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        optionsGroups: columnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterLabel: 'Invert Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert Y Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.LEGEND_POSITION,
      filterLabel: 'Lengeds Position',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Lengeds position",
        options: ['bottom', 'right'],
        displayOptions: ['Bottom', 'Right'],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SHOW_PIE_LABELS,
      filterLabel: 'Show labels',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Show labels",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.SHOW_PIE_LABELS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SORT_VALUE,
      filterLabel: 'Sort by',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        className: "block w-3/4 "
      },
      visible: () => {
        return isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
      },
      isInDiagramsPart: false,
    },
    {
      mainDataKey: undefined,
      useAsDataFilter: false,
      filterName: FiltersNames.PER_M2,
      filterLabel: 'Per m²',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Per m²",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.PER_M2),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Y Axis",
        // options: allSensorsDataKeys,
        // displayOptions: allSensorsDisplayNames,
        options: [valuesEnums.INDEX, columnsAPINamesEnums.UTILITY, ...dataColumns],
        displayOptions: [valuesEnums.INDEX, columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        optionsGroups: columnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      mainDataKey: columnsAPINamesEnums.SCENARIO,
      useAsDataFilter: false,
      filterName: FiltersNames.DIAGRAMS_SCENARIO,
      filterLabel: 'Scenario',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Scenario",
        options: scenarios,
        displayOptions: scenarios,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, FiltersNames.DIAGRAMS_SCENARIO,),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterLabel: 'Diagram type',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Diagram type",
        options: Object.values(diagramTypes).filter(type => type !== diagramTypes.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0),
        displayOptions: Object.values(diagramTypesDisplayOptions).filter(type => type !== diagramTypesDisplayOptions.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0),
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterLabel: 'Partitions',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Partitions",
        options: [columnsAPINamesEnums.UTILITY, ...dataColumns],
        displayOptions: [columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        optionsGroups: columnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterLabel: 'Stacks',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Stacks",
        options: [columnsAPINamesEnums.UTILITY, ...dataColumns],
        displayOptions: [columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        optionsGroups: columnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterLabel: 'Direction',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Direction",
        options: ["horizontal", "vertical"],
        displayOptions: ["Horizontal", "Vertical"],
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
      isInDiagramsPart: true,
    },
    // {
    //   filterLabel: 'Color',
    //   filterName: FiltersNames.DIAGRAMS_COLOR,
    //   filterType: FilterTypes.COLOR_INPUT,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     className: "block w-3/4 ",
    //   },
    //   visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
    //   isInDiagramsPart: true,
    // },
  ]


  const tempDataLevel = 'building'

  const useGetSpecificData = (config) => {
    let { dataPoint, dataLevel, timeframe, startDate, endDate, } = config;
    dataLevel = tempDataLevel
    const isDaily = timeframe === "daily" || !timeframe;
    const dates = [new Date(startDate ?? minDate), new Date(endDate ?? maxDate)];

    const inputs = {
      quantities: [dataPoint],
      scenarios,
      projectId,
      // startDate: isDaily ? new Date(minDate) : dates[0],
      // endDate: isDaily ? new Date(maxDate) : dates[1],
      startDate: new Date("2000-01-01"),
      endDate: new Date("2100-01-01"),
      dataLevel,
      interval: timeframe,
      enabled: !(dataLevel === "building" && isDaily) && !!dataPoint,
    };
    let {
      data: specificDataNotMemoized,
      isLoading,
      isError,
    } = useGetDashboardNew(inputs);

    const specificData = useMemo(() => {
      const mappedData = []
      specificDataNotMemoized?.forEach((row) => {
        const mappedDataRow = { ...row, [columnsAPINamesEnums.COUNT]: 1 }
        mappedData.push(mappedDataRow)
      })
      return mappedData
    }, [isLoading, dataPoint, timeframe, startDate, endDate, minDate, maxDate, dataLevel, scenarios, projectId,]);
    return { specificData, isLoading, isError }
  }

  const getColumnSortFunction = useCallback(({ dataKey, acsending = true, aValue, bValue }) => {
    let output = (aValue > bValue) ? 1 : -1
    if (dataKey === columnsAPINamesEnums.DAY_OF_WEEK) {
      output = getWeekdayNumber(aValue) - getWeekdayNumber(bValue)
    }
    else if (dataKey === columnsAPINamesEnums.MONTH) {
      output = getMonthNumber(aValue) - getMonthNumber(bValue)
    }
    else if (dataKey === columnsAPINamesEnums.YEAR_MONTH)
      output = (convertYearMonthToValidDate(aValue) > convertYearMonthToValidDate(bValue)) ? 1 : -1
    return acsending ? output : -output
  }, [generalDashboardData])


  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const {
      dataPoint,
      subDataPoint,
      selectedScenarios,
      selectedUtilities,
      chartType,
      diagrams,
      xAxis,
      yAxis,
      perM2,
      aggregateXAxis
    } = config
    return JSON.stringify([dataPoint, subDataPoint, selectedScenarios, selectedUtilities, chartType, diagrams, xAxis, yAxis, perM2, aggregateXAxis])
  }, [])

  const dataTransformator = useCallback((notScaledData, { diagrams, dataPoint, subDataPoint, selectedUtilities, chartType, xAxis, yAxis, perM2 }) => {
    const { partitions, stacks } = diagrams?.[0]
    const utilitiesColName = columnsAPINamesEnums.UTILITY

    const dataUtilities = getDataUtilities(notScaledData)

    let data
    if (perM2) {
      data = notScaledData?.map(row => {
        const scaledRow = {}
        Object.keys(row)?.forEach(col => {
          const shouldBeScaled = dataUtilities?.includes(col) || (col === columnsAPINamesEnums.CONSUMPTION)
          scaledRow[col] = shouldBeScaled ? (row[col] / (areaForM2Calculations ?? 1)) : row[col]
        })
        return scaledRow
      })
    } else {
      data = notScaledData
    }

    const activeUtilities = selectedUtilities?.length ? selectedUtilities : dataUtilities
    let dataPartitions, dataStacks;
    dataPartitions = (!partitions) ? [] :
      (partitions === utilitiesColName) ? activeUtilities : data?.map((e) => e[partitions])?.filter(distinctFilter);
    dataStacks = (!stacks) ? [] :
      (stacks === utilitiesColName) ? activeUtilities : data?.map((e) => e[stacks])?.filter(distinctFilter);

    let flattenData
    if ([partitions, stacks, xAxis, yAxis].includes(columnsAPINamesEnums.UTILITY)) {
      flattenData = getFlattenData({
        data,
        columnsToPivot: allSensorsDataKeys,
        valueName: dataPoint,
        newColumnName: utilitiesColName
      });

      if (selectedUtilities?.length)
        flattenData = flattenData.filter((e) =>
          selectedUtilities?.includes(e[utilitiesColName])
        );
    } else {
      flattenData = data?.map(row => {
        const utilitiesValues = Object.keys(row)?.filter(col => staticUtilities.includes(col))?.map(col => row[col])
        return { ...row, [dataPoint]: sum(utilitiesValues) }
      })
    }

    // if (selectedScenarios?.length)
    // flattenData = flattenData.filter((e) =>
    //   selectedScenarios?.includes(e[columnsNamesEnums.SCENARIO])
    // );

    if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {
      if (!subDataPoint) {
        flattenData = flattenData?.map((row) => {
          const filteredRow = {};
          if (partitions) filteredRow[partitions] = row[partitions];
          if (stacks) filteredRow[stacks] = row[stacks];
          return {
            ...filteredRow,
            dataPoint,
            [dataPoint]: row[dataPoint],
          };
        });
      } else {
        flattenData = staticUtilities.includes(subDataPoint) ? data : flattenData
        flattenData = flattenData?.map((row) => {
          const filteredRow = {};
          if (partitions) filteredRow[partitions] = row[partitions];
          if (stacks) filteredRow[stacks] = row[stacks];
          return {
            ...filteredRow,
            dataPoint: subDataPoint,
            [dataPoint]: row[dataPoint],
            [subDataPoint]: row[subDataPoint],

          }
        });
      }
      return { flattenData: flattenData || [], dataPartitions, dataStacks }
    }

    else if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE, chartTypesEnums.KPI].includes(chartType)) {
      if (!subDataPoint) {
        flattenData = flattenData?.map((row) => {
          const filteredRow = {};
          if (partitions) filteredRow[partitions] = row[partitions];
          if (stacks) filteredRow[stacks] = row[stacks];
          if (xAxis) filteredRow[xAxis] = row[xAxis];
          return {
            ...filteredRow,
            dataPoint,
            [dataPoint]: row[dataPoint],
          };
        })
      } else {
        flattenData = staticUtilities.includes(subDataPoint) ? data : flattenData
        flattenData = flattenData?.map((row) => {
          const filteredRow = {};
          if (partitions) filteredRow[partitions] = row[partitions];
          if (stacks) filteredRow[stacks] = row[stacks];
          if (xAxis) filteredRow[xAxis] = row[xAxis];
          return {
            ...filteredRow,
            dataPoint: subDataPoint,
            [dataPoint]: row[dataPoint],
            [subDataPoint]: row[subDataPoint],
          }
        });
      }
      return { flattenData: flattenData || [], dataPartitions, dataStacks }
    }

    else if (chartType === chartTypesEnums.HEATMAP) {
      if (!subDataPoint) {
        flattenData = flattenData?.map((row) => {
          const filteredRow = {};
          if (yAxis) filteredRow[yAxis] = row[yAxis];
          if (xAxis) filteredRow[xAxis] = row[xAxis];
          return {
            ...filteredRow,
            dataPoint,
            [dataPoint]: row[dataPoint],
          };
        })
      } else {
        flattenData = staticUtilities.includes(subDataPoint) ? data : flattenData
        flattenData = flattenData?.map((row) => {
          const filteredRow = {};
          if (yAxis) filteredRow[yAxis] = row[yAxis];
          if (xAxis) filteredRow[xAxis] = row[xAxis];
          return {
            ...filteredRow,
            dataPoint: subDataPoint,
            [dataPoint]: row[dataPoint],
            [subDataPoint]: row[subDataPoint],
          }
        });
      }

      const xLabels = xAxis === utilitiesColName ? activeUtilities : data
        ?.map((row) => row[xAxis])
        ?.filter(distinctFilter);

      xLabels?.sort((a, b) => getColumnSortFunction({ dataKey: xAxis, aValue: a, bValue: b }))

      const yLabels = yAxis === utilitiesColName ? activeUtilities : data
        ?.map((row) => row[yAxis])
        ?.filter(distinctFilter);

      yLabels?.sort((a, b) => getColumnSortFunction({ dataKey: yAxis, aValue: a, bValue: b }))

      return { flattenData: flattenData || [], xLabels, yLabels }
    }

    return { flattenData: flattenData || [] }

  }, [projectInfoParsed, building])

  const dataTransformatorMultithread = useCallback(() => {
    const worker = new Worker(new URL('../../../src/flat.worker.js', import.meta.url));
    const dataTransformatorWorker = Comlink.wrap(worker);

    return {
      calculate: async (notScaledData, { diagrams, dataPoint, subDataPoint, selectedUtilities, chartType, xAxis, yAxis, perM2 }, requestId) => {
        try {
          let result = await dataTransformatorWorker(
            notScaledData, {
            diagrams, dataPoint, subDataPoint, selectedUtilities, chartType, xAxis, yAxis, perM2,
            dataUtilities: getDataUtilities(notScaledData),
            areaForM2Calculations,
            columnsAPINamesEnums,
            allSensorsDataKeys,
            staticUtilities,
          })
          if (chartType === chartTypesEnums.HEATMAP) {
            let { flattenData, xLabels, yLabels } = result
            xLabels?.sort((a, b) => getColumnSortFunction({ dataKey: xAxis, aValue: a, bValue: b }))
            yLabels?.sort((a, b) => getColumnSortFunction({ dataKey: yAxis, aValue: a, bValue: b }))

            result = { flattenData, xLabels, yLabels }
          }
          return { ...result, requestId }

        } finally {
          worker.terminate()
        }
      },
      terminate: () => {
        worker.terminate();
      },
    };
  }, [projectInfoParsed, building])


  const getUnit = useCallback(({ quantity, config }) => columnsUnits?.[quantity], [])

  const getColorEntityDecorated = ({ quantity, chartType, diagrams }) => {
    if (staticUtilities.includes(quantity)) {
      return { categoryName: colorCategoriesEnums.COLUMNS, entityName: quantity }
    } else {
      return getColorEntity({ quantity, chartType, diagrams })
    }
  }

  const setFilteredData = () => { }

  let { data: savedPortfolioAndColorsConfigs, isError } = useGetEnergyDashbordConfigs(projectId)

  savedPortfolioAndColorsConfigs = savedPortfolioAndColorsConfigs?.config ?? loadDashboardDefaultConfigs()

  const getColumnDisplayNameCallback = useCallback((props) => getEnergyDashboardColumnDisplayName(props), [])

  const chartTypes = useMemo(() => [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.COMPOSED, chartTypesEnums.DOUGHNUT,
  chartTypesEnums.HEATMAP, chartTypesEnums.HISTOGRAM, chartTypesEnums.KPI, chartTypesEnums.LINE, chartTypesEnums.PIE
  ], [])


  const QueryClient = useQueryClient()
  const onSave = async (data) => {
    await postEnergyDashboardConfigs({ data: data, projectId })
    QueryClient.invalidateQueries(["energyDashbordConfigs", projectId, ENERGY_DASHBOARD_NAME_API_BODY])
  }

  const onReset = async () => {
    await deleteEnergyDashboardConfigs({ projectId })
    QueryClient.invalidateQueries(["energyDashbordConfigs", projectId, ENERGY_DASHBOARD_NAME_API_BODY])
  }


  return (
    <LoadingOrEmptyWrapper showLoading={isLoading || pIIsLoading} showEmpty={isError} height="400px">
      <DynamicDashboardMultiTab
        filteredData={generalDashboardData}
        setFilteredData={setFilteredData}
        generalDashboardData={generalDashboardData}
        // adjustConfigs={adjustConfigs}
        savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigs}
        onSave={onSave}
        onReset={onReset}
        defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}
        filtersCategories={[]}
        categoricalColumns={categoricalColumns}
        numericalColumns={numericalColumns}
        defaultColors={defaultColors}
        getColorEntity={getColorEntityDecorated}
        allAvailableFilters={() => []}

        dynamicChartsRowHeight={dynamicChartsRowHeight}
        allSettings={allSettings}
        allFilters={allFilters}
        chartTypes={chartTypes}
        useGetSpecificData={useGetSpecificData}
        getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
        dataTransformator={dataTransformator}
        dataTransformatorMultithread={dataTransformatorMultithread}
        getColumnSortFunction={getColumnSortFunction}
        getUnit={getUnit}
        getHoveredItemRelatedItems={() => []}
        isItemHovered={() => true}
        hoverEffect={false}

        setTriggerFlag={() => { }}
        renderMiniMapObjectClickModal={() => <></>}
        IdColumnName={columnsDisplayNamesEnums.ID}
        getColumnDisplayName={getColumnDisplayNameCallback}
        multithread={multithread}
      />
    </LoadingOrEmptyWrapper>
  );
};
