import { Paper, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import "rsuite/dist/rsuite-no-reset.min.css";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";
import { distinctFilter, findDataRange, getUniqueValuesFromJson, isNumeric } from "../utils/dataManipulation";

import "react-resizable/css/styles.css";
import { Table } from "../components";
import { isArray } from "lodash";
import { useSelector } from "react-redux";
import { useGetAllBuildings, useGetPortfolioDashboardConfigs } from "../api/hooks/allHooks";
import { AssetCardModal } from "../components/AssetOverview/AssetCardModal";
import { DynamicDashboardMultiTab } from "../components/chart/DynamicDashboardMultiTab";
import { categoricalColumns, columnsAPINamesEnums, columnsDisplayNamesEnums, columnsUnits, dataMapping, dataNeededColumns, defaultColors, FiltersNames, getPortfolioColumnDisplayName, infoBoxAndTableNeededColumns, isRelevantOption, PORTFOLIO_NAME_IN_API_BODY } from "../enums/AssetOverview/portfolio";
import { FilterTypes } from "../enums/components";
import { chartTypesEnums, colorCategoriesEnums, diagramTypes, diagramTypesDisplayOptions, getSortOptions, valuesEnums } from "../enums/dynamicDashboard";
import { getColorEntity, handleConfigChange, settingsOptions } from "../utils/dynamicDashboard";
import { eventNames, trackGAEvent } from "../functions/googleAnalytics";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { deleteEnergyDashboardConfigs, deletePortfolioDashboardConfigs, postEnergyDashboardConfigs, postPortfolioDashboardConfigs } from "../api/functions/all";

const filtersCategories = [
  {
    categoryName: 'Building profile',
    filtersNames: [
      columnsAPINamesEnums.PORTFOLIO,
      columnsAPINamesEnums.BUILDING_ID,
      columnsAPINamesEnums.BUILDING_NAME,
      columnsAPINamesEnums.USE_TYPE,
      columnsAPINamesEnums.BOUGHT_ON,
      columnsAPINamesEnums.SOLD_ON,
      columnsAPINamesEnums.RENTAL_UNITS,
      columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
      columnsAPINamesEnums.FLOOR_AMOUMT,
      columnsAPINamesEnums.HERITAGE_PROTECTED,
      columnsAPINamesEnums.BUILDING_TYPE_ID,
      columnsAPINamesEnums.BUILDING_TYPE,
      columnsAPINamesEnums.PRE_FABRICATED,
      columnsAPINamesEnums.LOGGIA,
      columnsAPINamesEnums.BALCONY,
      columnsAPINamesEnums.FOOTPRINT,
      columnsAPINamesEnums.CEILING_AREA,
      columnsAPINamesEnums.FACADE_AREA,
      columnsAPINamesEnums.PREMISE,
      columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
      columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      columnsAPINamesEnums.NET_AREA_WITH_BASEMENT,
      columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
      // columnsNamesEnums.ACCOUNTING_AREA,

    ],
  },
  {
    categoryName: 'Geography',
    filtersNames: [
      columnsAPINamesEnums.ZIP,
      columnsAPINamesEnums.CITY,
      columnsAPINamesEnums.MAIN_DISTRICT,
      columnsAPINamesEnums.SUB_DISTRICT,
      columnsAPINamesEnums.FULL_ADDRESS,
      columnsAPINamesEnums.STREET,
    ],
  },
  {
    categoryName: 'Energy & CO2',
    filtersNames: [
      columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A,
      columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A,
      columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE,
      columnsAPINamesEnums.NATURAL_GAS_AVAILABLE,
      columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      columnsAPINamesEnums.CO2_EMISSION,
      columnsAPINamesEnums.STRANDING_YEAR,
    ],
  },
]

const defaultPortfolioConfigsAndColorsConfigs = {
  "colors": {
    "Columns": {
      "construction_year": "#23cbe1",
      "carbon_dioxide_emission_value": "#000000",
      "Year of construction": "#3df0b4",
      "CO2 Emissions": "#141414"
    },
    "Values": {
      "Portfolio": {},
      "Building Name": {},
      "Zip": {},
      "City": {},
      "Main District": {},
      "Sub District": {},
      "Full Address": {},
      "Street": {},
      "Heritage Protected": {},
      "Building Type": {},
      "Pre Fabricated": {},
      "Loggia": {
        "Y": "#61ff98"
      },
      "Balkone": {},
      "Use type": {},
      "EnA-Eff.kl": {
        "No Data": "#2ec275",
        "": "#dd2727"
      },
      "portfolio": {},
      "descriptive_name": {},
      "post_code": {},
      "city": {},
      "main_district": {},
      "sub_district": {},
      "address": {},
      "street": {},
      "heritage_protected": {},
      "building_type": {},
      "pre_fabricated": {},
      "loggia": {},
      "balcony": {},
      "type": {},
      "energy_label": {}
    },
    "Mixed": {}
  },
  "tabs": [
    {
      "tab": "Portfolio summary",
      "filters": {
        "Energy Efficiency class": [
          null,
          "C",
          "A",
          "B"
        ],
        "Gross area": [
          29000,
          51430
        ],
        "Energy consumption": [
          3.63,
          66
        ],
        "Heat energy consumption": [
          16.35,
          123
        ],
        "Electrictiy consumption": [
          48.95,
          54
        ],
        "Stranding year": [
          2020,
          2035
        ],
        "Total Energy consumption": [
          37,
          309.5618896484375
        ],
        "CO2 Emissions": [
          0,
          108.7
        ],
        "City": [
          "Berlin"
        ],
        "city": [
          "Berlin"
        ]
      },
      "settings": [
        {
          "type": "1",
          "id": 6,
          "x": 0,
          "y": 0,
          "w": 12,
          "h": 7,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": null,
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "miniMap",
          "title": null,
          "xAxis": null,
          "yAxis": null,
          "dataPoint": "energy_consumption",
          "legendsPosition": "bottom"
        },
        {
          "type": "1",
          "id": 7,
          "x": 6,
          "y": 7,
          "w": 6,
          "h": 7,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": "gross_asset_basement_included",
              "diagramType": null,
              "color": "black",
              "partitions": null,
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "histogram",
          "title": "Gross Area Histogram",
          "xAxis": null,
          "yAxis": null,
          "dataPoint": null,
          "legendsPosition": "bottom"
        },
        {
          "type": "1",
          "id": 8,
          "x": 0,
          "y": 7,
          "w": 6,
          "h": 7,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": "construction_year",
              "diagramType": null,
              "color": "black",
              "partitions": null,
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "histogram",
          "title": "Year of construction",
          "xAxis": null,
          "yAxis": null,
          "dataPoint": "construction_year",
          "legendsPosition": "bottom"
        },
        {
          "type": "1",
          "id": 9,
          "x": 0,
          "y": 14,
          "w": 6,
          "h": 7,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": null,
              "diagramType": null,
              "color": "black",
              "partitions": "main_district",
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "doughnut",
          "title": null,
          "xAxis": null,
          "yAxis": null,
          "dataPoint": "Count",
          "legendsPosition": "right"
        },
        {
          "type": "1",
          "id": 10,
          "x": 6,
          "y": 14,
          "w": 6,
          "h": 7,
          "minW": 3,
          "minH": 3,
          "maxH": 20,
          "draggable": true,
          "diagrams": [
            {
              "dataPoint": null,
              "dataKey": "carbon_dioxide_emission_value",
              "diagramType": "scatter",
              "color": "black",
              "partitions": null,
              "stacks": null,
              "percentageBased": false,
              "direction": "horizontal",
              "title": null
            }
          ],
          "sortValue": null,
          "chartType": "composed",
          "title": "Energy vs. CO2",
          "xAxis": "energy_consumption",
          "yAxis": null,
          "dataPoint": null,
          "legendsPosition": "bottom",
          "aggregateXAxis": 0
        }
      ],
      "selectedFiltersNames": [
        "City",
        "city"
      ]
    }
  ]
}

const loadDashboardDefaultConfigs = () => {
  return defaultPortfolioConfigsAndColorsConfigs;
};

export const Portfolio = () => {

  useEffect(() => {
    trackGAEvent(eventNames.PORTFOLIO_VIEW)
  }, []);

  const { t } = useTranslation()
  const { projectId } = useParams();
  const { user } = useSelector((store) => store.user);
  const userId = user?.user_id
  const { data, isLoading } = useGetAllBuildings(userId);

  const dataPreprocessing = useMemo(() => {
    const mappedData = []
    const mappedInfoBoxAndTableData = []
    data?.forEach((row) => {
      const mappedDataRow = {}
      const mappedInfoBoxAndTableRow = {}
      Object.keys(row).forEach((oldDataKey) => {
        let value = row[oldDataKey]

        // const newDataKey = dataMapping[oldDataKey]
        const newDataKey = oldDataKey
        if (categoricalColumns.includes(newDataKey)) {
          value = value ?? valuesEnums.NO_DATA
        }
        if (infoBoxAndTableNeededColumns.includes(newDataKey)) {
          mappedInfoBoxAndTableRow[newDataKey] = value
        }

        if (dataNeededColumns.includes(newDataKey)) {
          mappedDataRow[newDataKey] = value
        }
        mappedDataRow[columnsAPINamesEnums.COUNT] = 1
      })

      mappedInfoBoxAndTableData.push(mappedInfoBoxAndTableRow)
      mappedData.push(mappedDataRow)
    })

    return [mappedData, mappedInfoBoxAndTableData]

  }, [data])

  const [generalDashboardData, infoBoxesAndTableData] = useMemo(() => dataPreprocessing, [dataPreprocessing])

  let dataColumns = [],
    numericalColumns = [];
  if (generalDashboardData?.length) {
    dataColumns = Object.keys(generalDashboardData[0]);
    numericalColumns = dataColumns.filter(col => !categoricalColumns.includes(col))
  }

  const dataColumnsDisplayOptions = dataColumns?.map(colName => getPortfolioColumnDisplayName({ colName }))

  const [filteredData, setFilteredData] = useState(generalDashboardData)

  const headers = [
    {
      label: columnsDisplayNamesEnums.BUILDING_NAME,
      dataKey: columnsAPINamesEnums.BUILDING_NAME,
      cellType: null,
      valueTransformation: (row) => <Stack gap={2}>
        <img className="w-12 h-12 object-contain object-center" src={row[columnsAPINamesEnums.IMAGE_URL_VALUE]} />
        <span>
          {row[columnsAPINamesEnums.BUILDING_NAME]}
        </span>
      </Stack>,
      subValueTransformation: (row) => null,
      valueClassName: 'py-1',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: columnsDisplayNamesEnums.PORTFOLIO,
      dataKey: columnsAPINamesEnums.PORTFOLIO,
      cellType: "",
      valueTransformation: (row) => row[columnsAPINamesEnums.PORTFOLIO],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
      dataKey: columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsAPINamesEnums.HEAT_ENERGY_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: columnsDisplayNamesEnums.USE_TYPE,
      dataKey: columnsAPINamesEnums.USE_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsAPINamesEnums.USE_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      dataKey: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      cellType: null,
      valueTransformation: (row) => row[columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: columnsDisplayNamesEnums.CO2_EMISSION,
      dataKey: columnsAPINamesEnums.CO2_EMISSION,
      cellType: null,
      valueTransformation: (row) => row[columnsAPINamesEnums.CO2_EMISSION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
  ]

  const tableFilteredData = infoBoxesAndTableData.filter(tableDataRow => {
    return filteredData?.some(mainDataRow => mainDataRow[columnsAPINamesEnums.ID] === tableDataRow[columnsAPINamesEnums.ID])
  })


  let { data: savedPortfolioAndColorsConfigs, isError } = useGetPortfolioDashboardConfigs(userId)

  const savedPortfolioAndColorsConfigsM = useMemo(() => {
    return savedPortfolioAndColorsConfigs?.config ?? loadDashboardDefaultConfigs()
  }, [savedPortfolioAndColorsConfigs])

  const dataColumnsOptionsGroups = filtersCategories?.map(category => {
    const sortedOptions = [...category.filtersNames]

    sortedOptions.sort()
    return { group: category.categoryName, options: sortedOptions }
  })

  dataColumnsOptionsGroups.unshift({ group: 'Count', options: [columnsAPINamesEnums.COUNT] })

  const allAvailableFilters = ({ allFiltersConfig, setConfig }) => [
    {
      filterName: columnsAPINamesEnums.PORTFOLIO,
      filterDisplayName: columnsDisplayNamesEnums.PORTFOLIO,
      mainDataKey: columnsAPINamesEnums.PORTFOLIO,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsDisplayNamesEnums.PORTFOLIO,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.PORTFOLIO),
      },
    },
    {
      filterName: columnsAPINamesEnums.USE_TYPE,
      filterDisplayName: columnsDisplayNamesEnums.USE_TYPE,
      mainDataKey: columnsAPINamesEnums.USE_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsDisplayNamesEnums.USE_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.USE_TYPE),
      },
    },
    {
      filterName: columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterDisplayName: columnsDisplayNamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsDisplayNamesEnums.ENERGY_EFFICIENCY_CLASS,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS),
      },
      config: allFiltersConfig,
      setConfig,
    },
    {
      filterName: columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.HEAT_ENERGY_TYPE),
      },
    },
    {
      filterName: columnsAPINamesEnums.PREMISE,
      filterDisplayName: columnsDisplayNamesEnums.PREMISE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.PREMISE,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.PREMISE,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.PREMISE], defaultMin: 0 })

      },
    },
    {
      filterName: columnsAPINamesEnums.FOOTPRINT,
      filterDisplayName: columnsDisplayNamesEnums.FOOTPRINT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.FOOTPRINT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.FOOTPRINT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.FOOTPRINT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterDisplayName: columnsDisplayNamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.GROSS_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterDisplayName: columnsDisplayNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT], defaultMin: 0 })
      },
    },

    {
      filterName: columnsAPINamesEnums.FLOOR_AMOUMT,
      filterDisplayName: columnsDisplayNamesEnums.FLOOR_AMOUMT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.FLOOR_AMOUMT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.FLOOR_AMOUMT,
        step: 1,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsAPINamesEnums.FLOOR_AMOUMT], defaultMin: 0
        })
      },
    },

    {
      filterName: columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
      filterDisplayName: columnsDisplayNamesEnums.YEAR_OF_CONSTRUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsDisplayNamesEnums.YEAR_OF_CONSTRUCTION,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsAPINamesEnums.YEAR_OF_CONSTRUCTION],
          defaultMin: 1900
        }),
      },
    },

    {
      filterName: columnsAPINamesEnums.STRANDING_YEAR,
      filterDisplayName: columnsDisplayNamesEnums.STRANDING_YEAR,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.STRANDING_YEAR,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsDisplayNamesEnums.STRANDING_YEAR,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsAPINamesEnums.STRANDING_YEAR],
          defaultMin: 1900

        })

      },
    },

    {
      filterName: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterDisplayName: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION], defaultMin: 0 })

      },
    },

    {
      filterName: columnsAPINamesEnums.CO2_EMISSION,
      filterDisplayName: columnsDisplayNamesEnums.CO2_EMISSION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.CO2_EMISSION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.CO2_EMISSION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.CO2_EMISSION], defaultMin: 0 })

      },
    },
    {
      filterName: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterDisplayName: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterDisplayName: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A], defaultMin: 0 })
      },
    },
    // ////////////////////////////////////////////////////////////////////////////////////

    {
      filterName: columnsAPINamesEnums.HERITAGE_PROTECTED,
      filterDisplayName: columnsDisplayNamesEnums.HERITAGE_PROTECTED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.HERITAGE_PROTECTED, config: allFiltersConfig, setConfig,
      props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.HERITAGE_PROTECTED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.HERITAGE_PROTECTED),
      },
    },
    {
      filterName: columnsAPINamesEnums.BUILDING_TYPE_ID,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_TYPE_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.BUILDING_TYPE_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.BUILDING_TYPE_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_TYPE_ID),
      }
    },
    {
      filterName: columnsAPINamesEnums.BUILDING_TYPE,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.BUILDING_TYPE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.BUILDING_TYPE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_TYPE),
      }
    },
    {
      filterName: columnsAPINamesEnums.PRE_FABRICATED,
      filterDisplayName: columnsDisplayNamesEnums.PRE_FABRICATED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.PRE_FABRICATED, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.PRE_FABRICATED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.PRE_FABRICATED),
      }
    },
    {
      filterName: columnsAPINamesEnums.LOGGIA,
      filterDisplayName: columnsDisplayNamesEnums.LOGGIA,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.LOGGIA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.LOGGIA,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.LOGGIA),
      }
    },
    {
      filterName: columnsAPINamesEnums.BALCONY,
      filterDisplayName: columnsDisplayNamesEnums.BALCONY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.BALCONY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.BALCONY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BALCONY),
      }
    },
    {
      filterName: columnsAPINamesEnums.CEILING_AREA,
      filterDisplayName: columnsDisplayNamesEnums.CEILING_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.CEILING_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.CEILING_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.CEILING_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.FACADE_AREA,
      filterDisplayName: columnsDisplayNamesEnums.FACADE_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.FACADE_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.FACADE_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.FACADE_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.NET_AREA_WITH_BASEMENT,
      filterDisplayName: columnsDisplayNamesEnums.NET_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.NET_AREA_WITH_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.NET_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.NET_AREA_WITH_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterDisplayName: columnsDisplayNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterDisplayName: columnsDisplayNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.EPC_CONSUMPTION_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterDisplayName: columnsDisplayNamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.DISTRICT_HEATING_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE),
      }
    },
    {
      filterName: columnsAPINamesEnums.NATURAL_GAS_AVAILABLE,
      filterDisplayName: columnsDisplayNamesEnums.NATURAL_GAS_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.NATURAL_GAS_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.NATURAL_GAS_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.NATURAL_GAS_AVAILABLE),
      }
    },
    {
      filterName: columnsAPINamesEnums.BOUGHT_ON,
      filterDisplayName: columnsDisplayNamesEnums.BOUGHT_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.BOUGHT_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.BOUGHT_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.BOUGHT_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.SOLD_ON,
      filterDisplayName: columnsDisplayNamesEnums.SOLD_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.SOLD_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.SOLD_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.SOLD_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.RENTAL_UNITS,
      filterDisplayName: columnsDisplayNamesEnums.RENTAL_UNITS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsAPINamesEnums.RENTAL_UNITS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.RENTAL_UNITS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.RENTAL_UNITS], defaultMin: 0 })
      }
    },
    {
      filterName: columnsAPINamesEnums.BUILDING_ID,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.BUILDING_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.BUILDING_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_ID),
      }
    },
    {
      filterName: columnsAPINamesEnums.BUILDING_NAME,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_NAME,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.BUILDING_NAME, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.BUILDING_NAME,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_NAME),
      }
    },
    {
      filterName: columnsAPINamesEnums.ZIP,
      filterDisplayName: columnsDisplayNamesEnums.ZIP,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.ZIP, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.ZIP,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.ZIP),
      }
    },
    {
      filterName: columnsAPINamesEnums.CITY,
      filterDisplayName: columnsDisplayNamesEnums.CITY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.CITY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.CITY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.CITY),
      }
    },
    {
      filterName: columnsAPINamesEnums.MAIN_DISTRICT,
      filterDisplayName: columnsDisplayNamesEnums.MAIN_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.MAIN_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.MAIN_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.MAIN_DISTRICT),
      }
    },
    {
      filterName: columnsAPINamesEnums.SUB_DISTRICT,
      filterDisplayName: columnsDisplayNamesEnums.SUB_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.SUB_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.SUB_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.SUB_DISTRICT),
      }
    },
    {
      filterName: columnsAPINamesEnums.FULL_ADDRESS,
      filterDisplayName: columnsDisplayNamesEnums.FULL_ADDRESS,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.FULL_ADDRESS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.FULL_ADDRESS,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.FULL_ADDRESS),
      }
    },
    {
      filterName: columnsAPINamesEnums.STREET,
      filterDisplayName: columnsDisplayNamesEnums.STREET,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsAPINamesEnums.STREET, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsDisplayNamesEnums.STREET,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.STREET),
      }
    }

  ]

  const dynamicChartsRowHeight = 50;
  const allSettings = (updateChart, config) => [
    {
      filterName: FiltersNames.TITLE,
      filterLabel: 'Chart title',
      filterType: FilterTypes.INPUT,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        className: "block w-3/4 ",
        label: "Title",
        placeholder: "Title",
        height: "4rem"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
      isInDiagramsPart: false,
    },
    {
      mainDataKey: 'dataPoint',
      useAsDataFilter: false,
      filterName: FiltersNames.DATAPOINT,
      filterLabel: 'Data point',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Data point",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.COMPOSED].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE, chartTypesEnums.HEATMAP].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.AGGREGATE_X_AXIS,
      filterLabel: 'Aggregation',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Aggregation",
        options: [0, 1],
        displayOptions: ["Don't aggregate", "Aggregate"],
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.AGGREGATE_X_AXIS,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_X_AXIS,
      filterLabel: 'Invert X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert X Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.Y_AXIS,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "y-axis",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterLabel: 'Invert Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert Y Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.LEGEND_POSITION,
      filterLabel: 'Lengeds Position',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Lengeds position",
        options: ['bottom', 'right'],
        displayOptions: ['Bottom', 'Right'],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SHOW_PIE_LABELS,
      filterLabel: 'Show labels',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Show labels",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.SHOW_PIE_LABELS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SORT_VALUE,
      filterLabel: 'Sort by',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        className: "block w-3/4 "
      },
      visible: () => {
        return isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
      },
      isInDiagramsPart: false,
    },
    {
      filterName: settingsOptions.COLOR_BAR_RANGE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      filterLabel: 'Color bar range',
      mainDataKey: columnsAPINamesEnums.PREMISE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Color bar range",
        className: "block w-3/4 ",
        changeByTyping: true,
        ...findDataRange({ data: filteredData, dataKeys: [config?.dataPoint], defaultMin: 0 })
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.COLOR_BAR_RANGE),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "y-axis",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterLabel: 'Diagram type',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Diagram type",
        options: Object.values(diagramTypes).filter(type => type !== diagramTypes.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0),
        displayOptions: Object.values(diagramTypesDisplayOptions).filter(type => type !== diagramTypesDisplayOptions.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0),

        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterLabel: 'Partitions',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Partitions",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterLabel: 'Stacks',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Stacks",
        options: dataColumns,
        displayOptions: dataColumnsDisplayOptions,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterLabel: 'Direction',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Direction",
        options: ["horizontal", "vertical"],
        displayOptions: ["Horizontal", "Vertical"],
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
      isInDiagramsPart: true,
    },
    // {
    //   filterLabel: 'Color',
    //   filterName: FiltersNames.DIAGRAMS_COLOR,
    //   filterType: FilterTypes.COLOR_INPUT,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     className: "block w-3/4 ",
    //   },
    //   visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
    //   isInDiagramsPart: true,
    // },
  ]
  const useGetSpecificData = (_config) => {
    return { specificData: [], isLoading: false, isError: false }
  }
  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const { dataPoint, chartType, diagrams, xAxis, yAxis } = config

    return JSON.stringify([dataPoint, chartType, diagrams, xAxis, yAxis])
  }, [])

  const dataTransformator = useCallback((data, { dataPoint, chartType, diagrams, xAxis, yAxis }) => {
    let output = []
    const { partitions, stacks } = diagrams?.[0]
    if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = { flattenData: flattenData || [] }
    }

    else if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE, chartTypesEnums.KPI].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = { flattenData: flattenData || [] }
    }

    else if (chartType === chartTypesEnums.HEATMAP) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (yAxis) filteredRow[yAxis] = row[yAxis];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      const xLabels = data
        ?.map((row) => row[xAxis])
        ?.filter(distinctFilter);
      xLabels?.sort()

      const yLabels = data
        ?.map((row) => row[yAxis])
        ?.filter(distinctFilter);
      yLabels?.sort()

      output = { flattenData: flattenData || [], xLabels, yLabels }
    } else {
      output = { flattenData: data || [] }
    }

    return output

  }, [])

  const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], [])

  const IdColumnName = columnsAPINamesEnums.ID


  const [triggerFlag, setTriggerFlag] = useState(false)

  const renderMiniMapObjectClickModal = ({ objects: clickedAssets, setObjects: setClickedAssets }) => {
    return clickedAssets?.map((clickedAsset, index) => {
      const id = clickedAsset[columnsAPINamesEnums.ID]
      const selectedBuilding = infoBoxesAndTableData?.find(building => building?.[columnsAPINamesEnums.ID] === id)
      return (
        <AssetCardModal key={id} triggerFlag={`${triggerFlag} ${id}`} onCardClick={() => {
          window.open(`${window.location.origin}/details/${id}/buildingProfile`)
        }}
          onClose={() => setClickedAssets(clickedAssets => clickedAssets.filter(asset => asset?.id !== id))}
          assetCardPosition={clickedAsset?.assetPosition}
          building={selectedBuilding}
        />
      )
    })
  }

  const getColumnDisplayNameCallback = useCallback((props) => getPortfolioColumnDisplayName(props), [])


  const QueryClient = useQueryClient()
  const onSave = async (data) => {
    await postPortfolioDashboardConfigs({ data: data, userId })
    QueryClient.invalidateQueries(["portfolioDashboardConfigs", userId, PORTFOLIO_NAME_IN_API_BODY])
  }

  const onReset = async () => {
    await deletePortfolioDashboardConfigs({ userId })
    QueryClient.invalidateQueries(["portfolioDashboardConfigs", userId, PORTFOLIO_NAME_IN_API_BODY])
  }

  return (
    <>
      <Helmet>
        <title>Portfolio | Leaftech</title>
      </Helmet>
      <LoadingOrEmptyWrapper showLoading={isLoading} showEmpty={isError} height="400px">
        <DynamicDashboardMultiTab
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          generalDashboardData={generalDashboardData}
          savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigsM}
          onSave={onSave}
          onReset={onReset}
          defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}
          filtersCategories={filtersCategories}
          categoricalColumns={categoricalColumns}
          numericalColumns={numericalColumns}
          defaultColors={defaultColors}
          getColorEntity={getColorEntity}
          allAvailableFilters={allAvailableFilters}

          dynamicChartsRowHeight={dynamicChartsRowHeight}
          allSettings={allSettings}
          allFilters={() => []}
          useGetSpecificData={useGetSpecificData}
          getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
          dataTransformator={dataTransformator}
          getUnit={getUnit}

          setTriggerFlag={setTriggerFlag}
          renderMiniMapObjectClickModal={renderMiniMapObjectClickModal}
          IdColumnName={columnsAPINamesEnums.ID}

          getColumnDisplayName={getColumnDisplayNameCallback}
          miniMapObjectsNameKey={columnsAPINamesEnums.BUILDING_NAME}
        />
        <Paper className="mt-8">
          <h4 className="t-heading-m ">
            {tableFilteredData?.length} {t("AssetOverview.filters.buildings")}
          </h4>
          <Table
            className="relative mt-6"
            data={[...tableFilteredData]}
            headers={headers}
            idColumnName="id"
            columnsMinWidth="10rem"
            onRowClick={(row) => {
              window.open(`${window.location.origin}/details/${row[columnsAPINamesEnums.ID]}/buildingProfile`)
            }}
          />
        </Paper>
      </LoadingOrEmptyWrapper >
    </>

  );
};
