export default {
  card: {
    title: "building information",
    "ASSET NAME": "ASSET NAME",
    TYPE: "TYPE",
    "ASSET ID": "ASSET ID",
    ADDRESS: "ADDRESS",
    "YEAR OF CONSTRUCTION": "YEAR OF CONSTRUCTION",
    "MODERNIZATION YEAR": "MODERNIZATION YEAR",
    "GROSS ASSET SIZE": "GROSS ASSET SIZE",
    "GROSS ASSET VALUE": "GROSS ASSET VALUE",
    "STRANDING YEAR": "STRANDING YEAR",
    "EPC CLASS": "EPC CLASS",
    "CO2 EMISSIONS": "CO2 EMISSIONS",
    "ENERGY CONSUMPTION": "ENERGY CONSUMPTION",
    'GROSS AREA (INCL. BASEMENT)': 'GROSS AREA (INCL. BASEMENT)',
    'Net LETTABLE AREA (WITHOUT BASEMENT)': 'Net LETTABLE AREA (WITHOUT BASEMENT)'
  },
  table: {
    title: "Building passport files",
    downloadAllFiles: "Download all files",
    columns: {
      name: "name",
      type: "type",
      "date modified": "date modified",
      files: "files",
    },
  },
  esg: {
    title: 'ESG Index',
    table: {
      columns: {
        yourBuilding: 'Your Building',

      }
    },
    ranks: {
      top15: 'Top 15%',
      top30: 'Top 30%',
      aboveAverage: 'Above Average',
      belowAverage: 'Below Average',
      unknown: 'Unknown',
      buildingType: 'Bulding Type',
      average: 'Average',
      quantity: 'Quantity'
    }
  },
  kpis: {
    title: "KPIs",
    totalEnergyConsumption: "total energy consumption",
    totalCarbonEmission: "total carbon emission",
    anticipatedStrandingYear: 'Anticipated Stranding year',
    kpisNotListed: "KPIs not listed.",
    crremM2Gross: 'CRREM (m² gross)',
    geg2024LettableArea: 'GEG 2024 (m² lettable area)',
    epcConsumption: 'EPC-Consumption',
    energyEfficiencyClass: 'Energy Efficiency Class',
    primaryEnergyConsumption: 'Primary Energy Consumption',

    primaryEnergyDemand: 'Primary Energy Demand',
    primaryEnergyDemandComparableBuilding: 'Primary Energy Demand Comparable Building',
    endEnergyDemand: 'End Energy Demand',
    endEnergyDemandComparableBuilding: 'End Energy Demand Comparable Building',

  },
  certificateCard: {
    certificateTitle: "Certificates",
    energyRatingTitle: "EU EPC rating certificates",
    epcTitle: 'EPC information',
    "Allotment date": "Allotment date",
    Area: "Area",
    "Valid until": "Valid until",
  },
};
